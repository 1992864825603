/* Basic Font */
$font-basic: Fira_Sans, Arial, sans-serif;

/* Colors variables */
$white: #ffffff;
$white-transparent-80: rgba(255, 255, 255, 0.8);
$white-transparent-30: rgba(255, 255, 255, 0.3);
$white-400: #f8f9ff;
$white-gradient: linear-gradient(180deg, #f0f2f5 48.98%, #F3F8F8 100%);
$white-gradient-transparent: linear-gradient(90deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.4));
$white-gradient-tablet: $white-gradient no-repeat center/cover;

$white-500: #f7f7f7;
$white_600: #fbfdff;
$white_700: #f6f7f8;

/* Black */

$deep-black: #000000;
$black: #333333;
$black_500: #333333;
$black_100: #151515;
$black_400: #333333;
$black-transparent-50: rgba(0, 0, 0, 0.5);
$black-transparent-10: rgba(0, 0, 0, 0.1);

$text-black: $black_400;

$transparent: transparent;

/* Gray Colors */

$gray_100: #e3e5e5;
$gray_150: #f0f2f5;
$gray_200: #c2c2c2;
$gray_250: #828282;
$gray_300: #909090;
$gray_350: #fafafa;
$gray_400: #323232;

$gray-transparent-50: rgba(51, 51, 51, 0.5);
$gray-transparent-60: rgba(51, 51, 51, 0.6);

/* Purple */

$purple: #9162c7;
$purple_400: #605ca8;

/* Red */

$red: #ff604a;
$red_default: #cc0000;
$red_100: rgba(235, 87, 87, .1);
$red_200: #ff7171;
$red_300: #eb5757;
$red_900: #6b3a2b;

/* Green */
$green_default: #219653;

$green_100: #68eddf;
$green_150: #53ffce;
$green_200: #33dfc1;
// ↓ значительно темнее, ↑ значительно светлее цвета, который заменён на $light-blue
$green_300: #10b5a1;
$green_320: #56a39a;
// ↑ значительно светлее цвета, который заменён на $blue
// ↓ значительно темнее цвета акцента, который заменён на $violet
$green_600: #015F56;
$green_700: #0c5a51;
$green_900: #063631;

/* Violet */
$violet: rgba(51, 36, 203, 1); // - #3324CB; // $green_400: #0e7b6e; $green_second: #168172;
$violet-transparent-50: rgba(51, 36, 203, 0.5); // rgba(14, 123, 110, 0.5), $green_transparent: #83bab4
$violet-transparent-25: rgba(51, 36, 203, 0.25); // rgba(14, 123, 110, 0.25)
$violet-transparent-15: rgba(51, 36, 203, 0.15); // $green_750: rgba(14, 123, 110, 0.2);
$violet-transparent-10: rgba(51, 36, 203, 0.1); // $green_t_50: rgba(14, 123, 110, 0.1);
$violet-transparent-4: rgba(51, 36, 203, 0.04); // $green_t_100: rgba(14, 123, 110, 0.04);

/* Blue  */

$blue: #0078fe; // $green_350: #23a28c; $green_380: #23a38c;
$light-blue: #00a5fe; // $green_250: #3acca2;

$blue_400: #5d7de2;
$blue_900: #2e2e62;
$blue-transparent_200: #dfe5f9;

/* Other colors */

$yellow: #f2c94c;
$orange: #f2994a;
$orange_900: #e86c27;
$bg-error: $white;

/* Typography colors */
$link-color: $violet;
$link-color-hover: darken($link-color, 5%) !default;
$link-color-gray: $gray_400;
$checked-color: $violet;
$calculate-amount-color: $violet;
$profile-user-email: $violet;

$refund-td-color: $light-blue;


$dark-color: $black;
$sign-out-color: $red;
$weak-pass-color: $red;
$strong-pass-color: $green_default;
$average-pass: $yellow;
$edit_profile_color: $gray_250;
$remove-profile-color: $gray_250;
$text-def: $gray_400;
$text-secondary: $gray_250;
$placeholder-color: $gray_200;
$label_color: $gray_400;
$link-hover: $link-color-hover;
$td-currency-color: $gray_250;
$card-nbr-bg: $white-500;
$descruptions-color: $gray_250;
$offers-count-color: $gray_250;
$subtitle_color: $gray_300;
$cashback-title: $black_100;
$service-free-td-color: $gray-transparent-50;
$breadcrumb-color: $gray_250;
$success-message-color: $white;

/* Buttons */
$btn-primary: $violet;
$btn-primary-border: $violet;
$btn-secondary-bg: $violet;
$btn-download-color: $violet;
$btn-light-primary-color: $violet;
$btn-transparent-color-hover: $violet;
$checkbox-color: $violet;
$sidebar-link-color-active: $violet;
$info-title-color: $violet;
$info-title-color-hovered: $green_900;

$sidebar-link-color: $violet-transparent-50;

$btn-primary-active: $violet-transparent-15;
$btn-primary-active-border: $violet-transparent-15;
$btn-disabled: $violet-transparent-15;
$btn-transparent-color-active: $violet-transparent-15;

$btn-download-bg: $violet-transparent-4;
$cheque-status-bg: $violet-transparent-4;

$btn-primary-light-color: $white;
$btn-primary-hovered: $blue;
$btn-primary-hovered-border: $blue;
$btn-text-color: $white;
$btn-transparent-color: $gray_400;
$scrollbar-tumb-bg: $gray_250;
$btn-clear: $red_default;
$btn-download-text-color: $white;
$btn-hover-text-color: $white;
$pagination-btn-color: $gray_250;

/* Borders */
$focused-input: $violet;
$border-result-separator: $violet;

$ng-selected-bg: $violet-transparent-4;
$focused-input-bg: $violet-transparent-4;
$dropdown-hover-bg: $violet-transparent-4;
$btn-prev-next-bg: $violet-transparent-4;

$border-color-basic: $gray_100;
$border-color-secondary: $gray_300;

$error-input: $red;
$hovered-input-color: $blue-transparent_200;
$dropdown-sign-hover-bg: $red_100;
$noty_bg: $red_default;
$body-color: $white-gradient;
$th-color: $gray_250;
$info-title-border: $gray_100;
$btn-icon-active-border: $transparent;

/* Others */
$ng-val-color: $violet;
$purchase-bg: $violet;
$loader-colors: $violet;
$calendar-date-selected-color: $violet;
$refound-pending-bg: $violet;
$link-decoration-color: $violet;
$link-decoration-color-hover: darken($violet, 5%) !default;
$cashback-value: $violet;
$triangle-color: $violet;
$loader-border: $violet;
$loginColor: $violet;
$custom-tab-title: $violet;
$active-status-color: $violet;

$dropdown-item-hover-bg: $violet-transparent-4;
$custom-tab-border: $violet-transparent-4;
$select-val-bg: $violet-transparent-4;
$table-bg-mobile: $violet-transparent-4;
$bg-cashback: $violet-transparent-4;
$mobile-sidebar-links-bg: $violet-transparent-4;

$date-period-faded: $violet-transparent-10;

$refunds-bg: $blue;

$refunds-pending-bg: $light-blue;
$refunds-pending-color: $light-blue;
$checked-option-color: $light-blue;

$icon-draft: $yellow;
$icon-validation-panding: $orange;
$icon-refound-pending: $blue_400;
$refunds-pending-icon-color: $green_150;
$refunds-pending-icon-white: $white;
$icon-refounded: $green_320;
$icon-purchase-amount-icon-color: $green_300;
$icon-purchase-amount-icon-white: $white;
$icon-rebounded-received-color: $green_200;
$icon-rebounded-received-icon-white: $white;
$icon-invalid: $red_default;
$icon-primary: $gray_200;
$navbar-color: $gray_200;

$card-first-bg: $gray_250;
$card-second-bg: $gray_300;
$card-third-bg: $light-blue;
$card-fourth-bg: $blue;
$card-fifth-bg: $violet;

$modals-box-shadow: 0 3px 5px rgba(179, 183, 196, .2),
  0 1px 18px rgba(179, 183, 196, .12), 0 6px 10px rgba(179, 183, 196, .14);
$header-box-shadow: 0px 1px 3px rgba(179, 183, 196, .2),
  0px 2px 2px rgba(179, 183, 196, .12), 0px 0px 2px rgba(179, 183, 196, .14);
$basic-box-shadow: 0 1px 8px rgba(179, 183, 196, .2),
  0 3px 4px rgba(179, 183, 196, .12), 0 3px 3px rgba(179, 183, 196, .14);
$dropdown-shadow: 0 1px 10px rgba(60, 60, 60, .2),
  0px 4px 5px rgba(60, 60, 60, .12), 0px 2px 4px rgba(60, 60, 60, .14);
$message-box-shadow: 0 7px 8px rgba(60, 60, 60, .2),
  0 5px 22px rgba(60, 60, 60, .12), 0 12px 17px rgba(60, 60, 60, .14);
$shop-item-shadow: 0px 1px 5px rgba(179, 183, 196, .2),
  0px 3px 4px rgba(179, 183, 196, .12), 0px 2px 4px rgba(179, 183, 196, .14);
$box-table-shadow: 0px 1px 8px rgba(139, 141, 150, .2),
  0px 3px 4px rgba(139, 141, 150, .12), 0px 3px 3px rgba(139, 141, 150, .14);
$landing-step-box-shadow: 0 15px 15px 9px #f4f2f7;
$shop-item-background: $white-gradient;
$flare-animation-gradient: $white-gradient-transparent;
$loader-icon-border-color: $white;
$login-form-bg: $white;
$text-light: $white;
$text-def-darken: $black_400;
$background-light: $white;
$ng-option-selected: $gray_400;
$errors-bg: $gray_400;
$error-normal-color: $white;
$error-warning-color: $orange;
$all_statuses_icon: $blue_900;
$paid_status: $green_320;
$final_status: $purple_400;
$invalid_status_date: $red_900;
$invalid_validation_status: $orange_900;
$error-hard-color: $red;
$offer-item-bg: $white;
$check-details-bg: $white;
$tooltip-bg: $white;
$error-text: $red;
$status-error-send: $red_default;
$pager-prev_next-color: $white-500;
$ng-select-container-bg: $white;
$ng-select-wrapper: $white;
$table-striped-odd-color: $white-500;
$notificationDescr: $gray_250;
$notificationDate: $gray_300;
$term-list-bg: $white;
$loader-bg: $white-transparent-80;
$button-loader-bg: $white-transparent-30;
$loader-border-section: $gray_100;
$loader-fill: $white;
$edit-profile-bg: $white;
$partner-offer-detail-bg: $white;
$partner-offer-price-bg: $white-500;
$service-free-color: $gray-transparent-50;
$body-bg: $white_700;
$input-bg: $white;
$dropdown_bg: $white;
$logoutColor: $red_200;
$captcha_bg: $gray_350;
$checkpoints-bg: $white;
$form-bg: $white;
$remove-btn-color: $red_300;
$product-section: $white_700;
$custop-paragraph-color: $gray_250;
$shopsFormbgColor: $gray_150;
$cheque-bottom-part-info-wrapper-bg: $white-500;
$skeleton_loader-bg: $white;
$verification-number-underline-color: $gray_400;
$footer-bg: $white;
$ng-select-bg: $white;
$tabs-titles-bg: $white;
$active-widget-status: $green_default;
$non-active-widget-status: $red_default;
$not-adds-status: $gray_200;
