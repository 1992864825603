/* Pagination Styles */

pagination-controls .ngx-pagination {
  &.responsive .small-screen {
    display: none;
  }

  li:not(.small-screen):not(.pagination-previous):not(.pagination-next) {
    display: inline-block !important;
  }

  @include flex-container(row, center, center);
  padding-top: 50px;

  li {
    display: inline-block;
    background: transparent;
    margin: 0 6px;
    max-width: 44px;
    width: 100%;
    padding: 0 !important;

    &.ellipsis {
      position: relative;
      text-align: center;

      span {
        padding: 0 !important;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 10;
      }

      &:hover {
        a,
        span {
          background: transparent;
          cursor: default;
          color: $text-secondary;
          font-weight: 400;
        }
      }
    }

    a,
    span {
      span {
        font-size: 18px;
        color: $pagination-btn-color;
        text-decoration: none;
        padding: 8px 11px !important;
      }
    }

    a {
      padding: 0 !important;

      &:focus {
        outline: none;
      }
    }

    &.pagination-previous,
    &.pagination-next {
      a {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background: url("/assets/icons/arrow-left-active.svg")
          no-repeat center,
          $pager-prev_next-color;
        margin: 0 auto;
      }
      margin-right: 25px;

      span,
      a {
        font-size: 0;
      }

      &.disabled {
        opacity: .3;
      }

      &:hover {
        a {
          cursor: pointer;
          background: url("/assets/icons/arrow-left-active.svg")
            no-repeat center,
            $pager-prev_next-color;
          opacity: .7;
        }
      }
    }

    &.pagination-next {
      transform: rotate(180deg);
      margin-left: 45px;
    }

    &.current {
      background: transparent !important;

      span {
        span {
          color: $link-color;
          font-weight: 500;
          background: $violet-transparent-4;
          border-radius: 3px;
        }
      }
    }

    &:hover {
      a {
        background: transparent;

        span {
          color: $link-color;
          font-weight: 700;
          background: $violet-transparent-4;
          border-radius: 3px;
        }
      }
    }
  }
}

.ngx-pagination .pagination-previous a:before,
.ngx-pagination .pagination-next a:after,
.ngx-pagination .pagination-previous.disabled:before,
.ngx-pagination .pagination-next.disabled:after
{
  display: none !important;
}

/* End Pagination Styles */
