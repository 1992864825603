@font-face {
  font-display: swap;
  font-family: 'Fira_Sans';
  font-style: normal;
  font-weight: 100;
  src: local('Fira Sans Thin'), local('FiraSans-Thin'),
    url('../../assets/fonts/fira-sans-100.woff2') format('woff2'),
    url('../../assets/fonts/fira-sans-100.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Fira_Sans';
  font-style: normal;
  font-weight: 200;
  src: local('Fira Sans ExtraLight'), local('FiraSans-ExtraLight'),
    url('../../assets/fonts/fira-sans-200.woff2') format('woff2'),
    url('../../assets/fonts/fira-sans-200.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Fira_Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Fira Sans Light'), local('FiraSans-Light'),
    url('../../assets/fonts/fira-sans-300.woff2') format('woff2'),
    url('../../assets/fonts/fira-sans-300.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Fira_Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Fira Sans Regular'), local('FiraSans-Regular'),
    url('../../assets/fonts/fira-sans-regular.woff2') format('woff2'),
    url('../../assets/fonts/fira-sans-regular.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Fira_Sans';
  font-style: normal;
  font-weight: 500;
  src: local('Fira Sans Medium'), local('FiraSans-Medium'),
    url('../../assets/fonts/fira-sans-500.woff2') format('woff2'),
    url('../../assets/fonts/fira-sans-500.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Fira_Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Fira Sans SemiBold'), local('FiraSans-SemiBold'),
    url('../../assets/fonts/fira-sans-600.woff2') format('woff2'),
    url('../../assets/fonts/fira-sans-600.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Fira_Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Fira Sans Bold'), local('FiraSans-Bold'),
    url('../../assets/fonts/fira-sans-700.woff2') format('woff2'),
    url('../../assets/fonts/fira-sans-700.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Fira_Sans';
  font-style: normal;
  font-weight: 800;
  src: local('Fira Sans ExtraBold'), local('FiraSans-ExtraBold'),
    url('../../assets/fonts/fira-sans-800.woff2') format('woff2'),
    url('../../assets/fonts/fira-sans-800.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Fira_Sans';
  font-style: normal;
  font-weight: 900;
  src: local('Fira Sans Black'), local('FiraSans-Black'),
    url('../../assets/fonts/fira-sans-900.woff2') format('woff2'),
    url('../../assets/fonts/fira-sans-900.woff') format('woff');
}


@font-face {
  font-display: swap;
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 200;
  src: local('Source Code Pro ExtraLight'), local('SourceCodePro-ExtraLight'),
    url('../../assets/fonts/SourceCodePro-ExtraLight.otf.woff2') format('woff2'),
    url('../../assets/fonts/SourceCodePro-ExtraLight.otf.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 300;
  src: local('Source Code Pro Light'), local('SourceCodePro-Light'),
  url('../../assets/fonts/SourceCodePro-Light.otf.woff2') format('woff2'),
  url('../../assets/fonts/SourceCodePro-Light.otf.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 400;
  src: local('Source Code Pro Regular'), local('SourceCodePro-Regular'),
    url('../../assets/fonts/SourceCodePro-Regular.otf.woff2') format('woff2'),
    url('../../assets/fonts/SourceCodePro-Regular.otf.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 500;
  src: local('Source Code Pro Medium'), local('SourceCodePro-Medium'),
    url('../../assets/fonts/SourceCodePro-Medium.otf.woff2') format('woff2'),
    url('../../assets/fonts/SourceCodePro-Medium.otf.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 600;
  src: local('Source Code Pro SemiBold'), local('SourceCodePro-SemiBold'),
    url('../../assets/fonts/SourceCodePro-Semibold.otf.woff2') format('woff2'),
    url('../../assets/fonts/SourceCodePro-Semibold.otf.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 700;
  src: local('Source Code Pro Bold'), local('SourceCodePro-Bold'),
    url('../../assets/fonts/SourceCodePro-Bold.otf.woff2') format('woff2'),
    url('../../assets/fonts/SourceCodePro-Bold.otf.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 900;
  src: local('Source Code Pro Black'), local('SourceCodePro-Black'),
    url('../../assets/fonts/SourceCodePro-Black.otf.woff2') format('woff2'),
    url('../../assets/fonts/SourceCodePro-Black.otf.woff') format('woff');
}
