.modal-header {
  padding: 32px 42px 16px;
  border-bottom: 0;
  flex-direction: column;

  h4 {
    font-size: 28px;
    line-height: 1.2;
    margin-bottom: 8px;
    font-weight: 700;
    max-width: none;
  }

  .close {
    position: absolute;
    right: 40px;
    top: 40px;
    width: 16px;
    height: 16px;
    background: url("/assets/icons/close-icon.svg") no-repeat center;
    background-size: contain;
    padding: 0;
    z-index: 1;
    opacity: 1;

    &:focus {
      opacity: 1 !important;
    }
  }
}

.modal-open .modal {
  z-index: 1055;
  padding: 1.75rem 0;
  display: flex !important;
  justify-content: center;
  overflow-y: scroll;

  .modal-dialog {
    width: 100%;
    margin: auto;

    .info-field-info {
      padding: 0 32px;
    }

    &.modal-lg {
      max-width: 1000px;
    }
  }

  .modal-header {
    align-items: center;

    &.checks-qr {
      align-items: flex-start;
    }
  }

  .modal-footer {
    border-top: none;
    flex-wrap: nowrap;

    .btn {
      max-width: 145px;
      width: 100%;

      &.btn-link {
        color: $link-color;

        &:hover {
          color: $btn-hover-text-color;
        }
      }
    }
  }

  &.remove-profile-success-message-modal {
    .modal-dialog {
      width: 320px;
    }
  }

  &.agreement-view-modal {
    .modal-body {
      padding: 25px;
    }
  }

  &.checkpoint-modal,
  &.checks-info {
    align-items: flex-start;

    .modal-dialog {
      max-width: 1000px;
    }
  }

  &.add-shop-modal {
    align-items: flex-start;
    padding: 0;
  }

  &.scan-modal {
    overflow: hidden;
    padding: 65px 0 80px 0;

    .modal-dialog {
      min-width: 690px;
      height: 100%;
      max-height: 776px;

      .modal-content {
        height: 100%;
        overflow: hidden;
      }
    }

    &.scan-qr {
      .modal-dialog {
        max-height: 740px;

        .modal-footer {
          padding-top: 32px;
        }
      }
    }

    &.upload-modal {
      .modal-dialog {
        max-height: 462px;
      }

      .modal-footer {
        padding: 40px 43px 32px;
      }

      .modal-body {
        padding-bottom: 0;
      }
    }

    .modal-body {
      overflow: auto;
      padding: 0 48px 8px 48px;
    }
  }

  &.add-payment-modal {
    .modal-dialog {
      min-width: 690px;
      max-width: 690px;

      .modal-body {
        padding-bottom: 0;
      }

      .modal-footer {
        padding: 40px 45px 45px;
      }
    }
  }

  &.scan-error-modal {
    .modal-dialog {
      min-width: 420px;
      max-width: 420px;
    }

    .modal-body {
      padding: 0;
    }

    .modal-footer {
      flex-direction: column;
      justify-content: center;
      padding: 48px 48px 32px 48px;

      .btn {
        width: 100%;
        max-width: 324px;
      }
    }

    .modal-footer> :not(:last-child) {
      margin-right: 0;
    }

    .modal-footer> :not(:first-child) {
      margin-left: 0;
      margin-top: 16px;
    }
  }

  &.draft-receipt-modal {
    .modal-dialog {
      min-width: 420px;
      max-width: 420px;
    }

    .modal-header {
      padding: 0;

      .close {
        z-index: 1;
      }
    }

    .modal-body {
      padding: 56px 48px 0;
    }

    .modal-footer {
      flex-direction: column;
      justify-content: center;
      padding: 48px 48px 32px 48px;

      .btn {
        width: 100%;
        max-width: 324px;
      }
    }

    .modal-footer> :not(:last-child) {
      margin-right: 0;
    }

    .modal-footer> :not(:first-child) {
      margin-left: 0;
      margin-top: 16px;
    }
  }

  &.profile-modal {
    align-items: flex-start;
  }

  &.new-chashbox,
  &.add-shop-modal,
  &.profile-modal {
    .modal-header {
      padding-top: 80px;
    }

    .modal-dialog {
      max-width: 690px;
    }

    .modal-content {
      padding: 0 30px;
    }
  }

  &.small-window,
  &.profile-modal-edit,
  &.delete-cashbox {
    .modal-header {
      padding-top: 80px;
    }

    .modal-dialog {
      max-width: 370px;
    }
  }

  &.excise-product {
    padding-top: 61px;
    padding-bottom: 60px;

    .modal-dialog {
      max-width: 690px;
    }
  }

  &.new-receipt {
    padding-top: 61px;
    padding-bottom: 60px;

    .modal-dialog {
      max-width: 1018px;
      height: 100%;
      max-height: 800px;
      min-height: 750px;
      margin: 0;

      .modal-content {
        height: 100%;
        overflow: hidden;
      }

      .modal-body.main-modal-body {
        height: 100%;
        overflow: hidden;

        .modal-body-inner {
          height: 100%;
          overflow: hidden;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  &.new-widget {
    padding-top: 61px;
    padding-bottom: 60px;

    .modal-dialog {
      min-width: 696px;
      height: 100%;
      max-height: 746px;
      min-height: 746px;
      margin: 0;

      .modal-header {
        padding: 0;
      }

      .modal-content {
        height: 100%;
        overflow: hidden;
      }

      .modal-footer {
        padding: 0 45px 32px;
      }

      .modal-body.main-modal-body {
        height: 100%;
        overflow: hidden;
        padding: 80px 45px 32px;

        .modal-body-inner {
          height: 100%;
          overflow: hidden;
          display: flex;
          flex-direction: column;
        }
      }

      app-create-edit-widget-modal {
        height: 100%;
        display: flex;
        flex-direction: column
      }
    }
  }

  &.create-internet-shop {
    align-items: flex-start;
    padding: 0;

    .modal-open .modal {
      overflow-y: hidden;
    }

    .modal-dialog {
      min-width: 696px;

      .modal-header {
        padding: 32px 42px 16px;
      }

      .modal-content {
        padding: 0 30px;
        overflow: hidden;
      }

      .modal-footer {
        padding: 0 15px 32px;
      }

      .modal-body {
        overflow-y: scroll;
      }

      // app-create-edit-shop-modal {
      //   height: 100%;
      //   display: flex;
      //   flex-direction: column
      // }
    }
  }

  &.edit-localization {
    padding-top: 26vh;
    padding-bottom: 60px;

    .modal-dialog {
      min-width: 650px;
      height: 100%;
      max-height: 500px;
      min-height: 500px;
      margin: 0;

      .modal-header {
        padding: 0;
      }

      .modal-content {
        height: 100%;
        overflow: hidden;
      }

      .modal-footer {
        padding: 0 45px 32px;
      }

      .modal-body.main-modal-body {
        height: 100%;
        overflow: hidden;
        padding: 40px 45px 32px;

        .modal-body-inner {
          height: 100%;
          overflow: hidden;
          display: flex;
          flex-direction: column;
        }
      }

      app-edit-widget-localization {
        height: 100%;
        display: flex;
        flex-direction: column
      }
    }
  }

  &.delete-receipt {
    .modal-dialog {
      max-width: 370px;
    }
  }

  &.admin-modal-page-creator {
    align-items: flex-start;

    .modal-dialog {
      max-width: 1170px;
    }

    .modal-header {
      padding-top: 80px;
    }
  }

  &.small-window-filters {
    align-items: flex-start;

    .modal-dialog {
      max-width: 750px;

      .modal-header {
        padding-top: 80px;
      }
    }
  }

  &.new-checkpoint,
  &.new-offer {
    .modal-dialog {
      max-width: 690px;
      width: 100%;

      .modal-body {
        overflow-x: hidden;
      }

      .modal-content {
        padding: 45px 45px 20px;
      }
    }
  }

  &.delete-offer .modal-dialog,
  &.delete-checkpoint .modal-dialog {
    max-width: 370px;
    width: 100%;

    .modal-content {
      padding: 48px 35px 20px;
    }
  }

  &.download-offer {
    &.small-header {
      .modal-header {
        padding-top: 0;
      }
    }

    .modal-body {
      overflow-x: hidden;
      padding: 16px 35px;
    }

    .modal-footer {
      padding: 32px 35px 16px;

      .row {
        margin-right: -15px;
        margin-left: -15px;
      }
    }

    &.fns-modal {
      .modal-dialog {
        max-width: 850px;
      }
    }

    &.wide-modal {
      .modal-dialog {
        max-width: 450px;
      }
    }

    .modal-dialog {
      max-width: 370px;
      width: 100%;

      .modal-content {
        padding: 48px 0 20px;
      }

      button {
        max-width: none;
      }
    }
  }
}

.modal-text {
  color: $text-def;
  font-size: 14px;
  text-align: center;

  span {
    font-weight: 600;
  }
}

.modal-subtitle {
  color: $subtitle_color;
  font-size: 14px;
}

app-qrcode-modal {
  .modal-header {
    padding: 32px;
  }

  .modal-body {
    max-width: 400px;
    padding: 0;
    width: 100%;
    margin: 0 auto 30px;
  }

  p.message {
    max-width: 310px;
    margin: 0 auto;
    line-height: 22px;
  }

  .account-settings-btn {
    max-width: 280px;
    width: 100%;
  }
}

.modal-content {
  border: 0 !important;

  .ng-select.ng-select-opened>.ng-select-container .ng-arrow {
    top: 2px;
  }
}

@media (max-width: 991px) {
  .modal-open .modal {
    &.add-payment-modal {
      .modal-dialog {
        min-width: 250px;
        max-width: 480px;
        height: 100%;
        padding: 16px 0;

        .modal-content {
          height: 100%;
          overflow: auto;
        }

        .modal-header {
          padding: 80px 16px 0;
          margin-bottom: 24px;

          h4.modal-title {
            margin-bottom: 0;
          }
        }

        .modal-body {
          padding-top: 0;

          .mobile-btns {
            margin-bottom: 32px;
          }

          .modal-body-inner {
            padding: 0;
          }

          .info-field-info {
            font-weight: 400;
            font-size: 12px !important;
            line-height: 20px !important;
          }
        }

        .modal-footer {
          flex-direction: column;
          padding: 32px 16px 24px;

          button.btn {
            max-width: 100%;

            &.btn-link {
              order: 2;
              color: $black_500;
              margin-top: 16px;

              &:hover {
                background-color: transparent !important;
                border-color: transparent !important;
              }
            }
          }
        }
      }
    }

    &.scan-modal {
      .modal-dialog {
        min-width: 480px;
        max-height: 776px;

        .modal-body {
          padding: 0;

          .description {
            line-height: 22px;
            min-height: 44px;
          }
        }

        .modal-footer {
          justify-content: center;
        }
      }

      &.upload-modal {
        .modal-dialog {
          max-height: 358px;
        }

        .modal-footer {
          padding: 16px 16px 24px;
        }
      }

      &.scan-qr {
        .modal-dialog {
          max-height: 776px;

          .modal-footer {
            padding-top: 16px !important;
          }
        }
      }
    }

    overflow: hidden;

    &.new-receipt {
      padding: 16px;

      .modal-dialog {
        max-height: 100%;
        min-width: 100%;

        .modal-content {
          height: 100%;
          overflow: hidden;
        }

        .modal-body.main-modal-body {
          padding: 80px 0 32px;
          height: 100%;
          overflow: scroll;

          .modal-body-inner {
            padding: 0;
            height: auto;
            overflow: hidden;
            display: flex;
            flex-direction: column;
          }
        }
      }
    }

    &.draft-receipt-modal {
      padding: 16px 0;

      .modal-dialog {
        min-width: 370px;
        max-width: 370px;
        max-height: 480px;
        height: 100%;

        .modal-content {
          height: 100%;
          overflow-y: auto;
        }
      }

      .modal-body {
        padding: 48px 16px 0;
      }

      .modal-footer {
        padding: 32px 16px 24px;

        .btn {
          max-width: 100%;
        }
      }
    }

    &.scan-error-modal {
      .modal-dialog {
        align-items: center;
        display: flex;
        min-width: 370px;
        padding: 0;
        max-width: 370px;
        height: auto;

        .modal-content {
          height: 100%;
          overflow-y: auto;
        }

        .modal-header {
          padding: 48px 16px 16px;

          .title-error {
            margin-top: 24px;
          }

          .close {
            margin: 0;
            top: 32px;
            right: 32px;
          }
        }

        .modal-footer {
          padding: 32px 16px 24px;

          button.btn {
            max-width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 767.5px) {
  .receipt-modal {
    .modal-open .modal.new-receipt .modal-dialog .modal-body.main-modal-body .modal-body-inner {
      height: auto !important;
    }
  }

  .modal-open .modal {
    &.agreement-view-modal {
      padding: 10px 0px 50px 0;
      .modal-backdrop {
        width: 105vw;
        height: 105vh;
      }

      .modal-body {
        height: 74vh;
      }

      .modal-dialog {
        height: 100%;
      }

      .modal-content {
        height: 95%;
        width: 95%;
      }
    }

    &.scan-modal {
      padding: 16px;

      .modal-dialog {
        min-width: 100%;
        height: 100%;

        .modal-body {
          text-align: center;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          height: 100%;

          .description {
            padding: 0 16px;
            margin-bottom: 31px;
          }

          .wrapper {
            flex: 1;
            overflow: hidden;
          }

          padding: 0;
        }

        .modal-footer {
          justify-content: center;
        }
      }

      &.upload-modal {
        .modal-dialog {
          height: auto;

          .description {
            margin-bottom: 0;
          }
        }
      }

      &.scan-qr {
        .modal-dialog {
          .modal-footer {
            padding-top: 38px !important;
          }
        }
      }

      &.scan-camera-error {
        .modal-dialog {
          height: auto;
        }
      }
    }

    &.new-receipt {
      padding: 0;

      .modal-dialog {
        padding: 16px;
        min-height: 100%;
      }
    }

    &.add-payment-modal {
      .modal-dialog {
        min-width: 100%;
        padding: 16px;
      }

      .modal-header {
        margin-bottom: 32px;

        .close {
          margin: 0;
          top: 32px;
          right: 32px;
        }
      }

      .modal-body {
        .form-group {
          margin-bottom: 22px;
        }

        .mobile-btns {
          flex-direction: column;
          padding-left: 24px;
          margin-bottom: 32px;
        }

        button.btn.btn-big-icon {
          display: inline-flex;
          align-items: center;
          width: auto;
          height: 64px;
          white-space: nowrap;

          .text {
            margin-top: 0;
            text-align: left;
          }

          svg-icon {
            margin-right: 16px;
            flex-shrink: 0;
          }

          &:nth-of-type(2) {
            margin-top: 16px;
          }
        }

        .input-group .ng-select .ng-select-container {
          border: 1px solid $border-color-basic !important;
          height: 48px !important;
          border-radius: 2px;
          padding: 0 8px 0 16px;
          align-items: center;

          .ng-value-container .ng-value {
            position: static;
          }
        }
      }

      .modal-footer {
        min-height: 168px;
      }
    }

    &.scan-error-modal {
      .modal-dialog {
        padding: 16px;
        max-width: 100%;
        min-width: 100%;
        height: auto;

        .modal-body {
          .error-wrapper {
            padding: 0 16px 0;
          }
        }

        .modal-footer {
          padding-top: 32px;
        }
      }

      &.add-additional {
        .modal-dialog {
          height: auto;
        }
      }
    }

    &.draft-receipt-modal {
      padding: 16px;

      .modal-dialog {
        margin: 0;
        max-width: 100%;
        min-width: 100%;
      }

      .modal-body {
        padding: 48px 16px 0;
      }

      .modal-footer {
        padding: 32px 16px 24px;

        .btn {
          max-width: 100%;
        }
      }
    }

    &.delete-receipt {
      .modal-dialog {
        margin-right: 0;
        margin-left: 0;
        min-width: 100%;
        padding: 0 16px;
      }
    }

    &.download-offer {
      .modal-footer {
        .row {
          .col-12+.col-12 {
            margin-top: 12px;
          }
        }
      }
    }
  }

}
