@import "./../variables";
@import "./../mixins";

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle,
.btn-secondary:not(:disabled):not(.disabled):active:focus {
  background-color: $btn-secondary-bg;
  opacity: 0.7;
  box-shadow: none;
}

.was-validated .form-control:invalid:focus {
  box-shadow: none !important;
  border-color: $error-input;
  padding-right: 8px !important;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.btn {
  border-radius: 2px !important;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.25px;
  text-transform: uppercase;
  font-family: $font-basic;
  height: 48px;
  line-height: 24px;
  padding: 0 16px;
  font-weight: 600;
  box-shadow: none !important;
  text-decoration: none !important;
  position: relative;
  @include trans(0);

  &:not(.disabled),
  &:not(:disabled) {
    @include trans();
  }

  .loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $loader-colors;
    overflow: hidden;

    .loader-icon {
      display: block;
      width: 20px;
      height: 20px;
      border: 3px solid $loader-icon-border-color;
      border-radius: 50%;
      position: absolute;
      animation: rotate 4s infinite ease;
      margin-top: -10px;
      margin-left: -10px;
      left: 50%;
      top: 50%;

      &:after {
        content: "";
        position: absolute;
        height: 8px;
        width: 100%;
        background: $loader-colors;
        left: 5px;
        top: 50%;
        margin-top: -4px;
      }
    }
  }

  &.upload {
    color: $btn-primary;
    border: 1px solid $border-color-basic;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    @include flex-container(row, center, center);

    &.is-invalid {
      border-color: $error-input;
      color: $error-input;

      .icon {
        svg * {
          fill: $error-input;
        }
      }
    }

    .icon {
      margin-right: 0;
      width: 20px;
      text-align: left;
      height: 30px;

      & + span {
        @include text-ellipsis(75%);
      }
    }

    &:hover:not(.uploaded),
    &:not(:disabled):not(.disabled):active:not(.uploaded),
    &:not(:disabled):not(.disabled):active:focus:not(.uploaded) {
      background: $btn-primary;
      border-color: $btn-primary-border;
      color: $white;

      .icon svg * {
        fill: $white;
      }
    }

    &.uploaded {
      justify-content: flex-start;
      color: $text-def;
      padding-right: 40px;

      &:hover {
        border-color: $border-color-basic;
      }

      & + span {
        position: absolute;
        right: 14px;
        top: 50%;
        margin-top: 2px;
        width: 20px;
        height: 20px;
        padding: 0;
        background: url("/assets/img/close-icon-new.svg") no-repeat center;
        background-size: contain;
        font-size: 0;

        &:hover {
          opacity: 0.8;
          cursor: pointer;
        }
      }
    }
  }

  &.purches-btn {
    @include flex-container(row, flex-start, center);
    padding: 0;
    background: transparent !important;
    color: $btn-primary !important;
    border-color: transparent !important;

    .icon {
      width: 14px !important;
      height: 14px;
      line-height: 14px;

      svg {
        vertical-align: top;

        * {
          fill: $btn-primary;
        }
      }
    }

    &:hover {
      opacity: 0.7;
    }

    &[disabled] {
      background: transparent !important;
      color: $btn-primary !important;
      border-color: transparent !important;
    }
  }

  &.clear-btn {
    white-space: nowrap;
    color: $btn-clear;
    opacity: 0.5;

    svg * {
      fill: $btn-clear;
    }

    .icon {
      display: inline-block;
      width: 14px;
      height: 14px;
    }

    &:not(:disabled):not(.disabled):active:focus {
      background-color: transparent;
      border-color: transparent;
      opacity: 1;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &:hover {
    border-color: transparent;
  }

  &.btn-link-filter {
    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -8px;
    background: url("/assets/icons/icon-date.svg") no-repeat center;
    height: 16px;
    width: 16px;

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }

  svg {
    max-width: 100%;
    max-height: 100%;
  }

  &.filter-submit-btn {
    @include flex-container(row, space-between, center);
    max-width: 168px;
    width: 100%;
    text-align: left;

    .filters-count {
      @include flex-container(row, center, center);
      width: 16px;
      height: 16px;
      background: $white;
      border-radius: 50%;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.25px;
      color: $text-def;
      vertical-align: middle;
      .counter-text {
        margin-top: 2px;
        min-width: 16px;
        align-items: center;
        text-align: center;
      }
    }

    .text {
      line-height: 16px !important;
    }

    .icon.filter {
      width: 18px;
      height: 22px;
      margin-left: 0;
      margin-right: 0;

      svg {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  &.ngb-dp-arrow-btn {
    color: $calendar-date-selected-color;
    font-size: 12px;
    width: 22px;
    height: 22px;

    &.btn-link {
      font-size: 12px;
      color: $calendar-date-selected-color;
      background: $btn-transparent-color-active !important;
      border-radius: 50% !important;
    }
  }

  &.btn-primary {
    background: $btn-primary;
    border-color: $btn-primary-border;

    &:not(:disabled):not(.disabled):active:focus {
      background: $btn-primary-active;
      border-color: $btn-primary-active-border;
    }

    &:not(:disabled):not(.disabled):active {
      background: $btn-primary-active;
      border-color: $btn-primary-active-border;
    }

    &:hover {
      background: $btn-primary-hovered;
      border-color: $btn-primary-hovered-border;
    }
  }

  &.btn-big-icon {
    background: transparent;
    border-color: none;
    color: $violet;
    width: 181px;
    height: 129px;
    font-weight: 500;
    line-height: 17px;
    .text{
      margin-top: 18px;
    }
  }

  &:not(:disabled):not(.disabled):active:focus {
    background: $btn-primary-active;
    border-color: $btn-primary-active-border;
  }

  &.disabled,
  &[disabled] {
    box-shadow: none;
    background: $btn-disabled !important;
    color: $btn-text-color !important;
    border-color: transparent !important;
  }

  &.remove-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -10px;
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: url("/assets/icons/close-circle-2.svg")
      no-repeat center/contain;
    margin-left: 8px;
    padding: 0;
    z-index: 10;

    &:not(:disabled):not(.disabled):active:focus {
      background: url("/assets/icons/close-circle-3.svg")
        no-repeat center/contain;
      border-color: transparent !important;
    }
  }

  &.transparent,
  &.btn-link {
    color: $btn-transparent-color;
    background: transparent !important;
    border-color: transparent !important;
    @include trans(0.1, ease-in);

    &:hover {
      background: $btn-transparent-color-hover !important;
      border-color: $btn-transparent-color-hover !important;
      color: $btn-text-color;
    }

    &:not(:disabled):not(.disabled):active:focus {
      color: $text-light;
      background: $btn-transparent-color-active !important;
      border-color: $btn-transparent-color-active !important;
    }

    &.btn-link-filter {
      min-width: 24px;
      padding: 0;

      &:hover {
        background: transparent !important;
        border-color: transparent !important;
        color: $btn-text-color;
      }
    }
  }

  &.full-width {
    width: 100%;
  }

  &.btn-secondary {
    background-color: $btn-primary;
    border-color: $btn-primary-border;
  }

  &.reverse {
    background-color: transparent;
    color: $btn-primary;
    border: 1px solid $btn-primary-border;

    .icon {
      width: auto;
      color: $btn-secondary-bg;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):active:focus {
      background: $btn-primary;
      border-color: $btn-primary-border;
      color: white;

      .icon {
        color: $text-light;
      }
    }
  }

  &.primary-light {
    font-size: 16px;
    text-transform: none;
    background: $btn-light-primary-color;
    font-weight: 400;
    color: $btn-primary-light-color !important;
  }

  &.btn-download {
    font-size: 16px;
    text-transform: none;
    background: url("/assets/icons/download.svg")
        no-repeat top 14px left 10px,
      $btn-download-bg;
    font-weight: 400;
    padding: 10px 12px 10px 38px;
    color: $btn-download-color !important;

    &:not(:disabled):not(.disabled):active:focus {
      background: url("/assets/icons/download.svg")
          no-repeat top 14px left 10px,
        $btn-download-bg;
    }

    &:hover {
      background: url("/assets/icons/download.svg")
          no-repeat top 14px left 10px,
        $btn-download-bg;
      opacity: 0.7;
    }
  }

  &.btn-print {
    font-size: 16px;
    text-transform: none;
    background: url("/assets/icons/print.svg")
        no-repeat top 16px left 10px,
      $btn-download-bg;
    font-weight: 400;
    padding: 10px 12px 10px 38px;
    color: $btn-download-color !important;

    &:not(:disabled):not(.disabled):active:focus {
      background: url("/assets/icons/print.svg")
          no-repeat top 16px left 10px,
        $btn-download-bg;
    }

    &:hover {
      background: url("/assets/icons/print.svg")
          no-repeat top 16px left 10px,
        $btn-download-bg;
      opacity: 0.7;
    }
  }

  &.btn-outline-secondary {
    color: $btn-primary;
    border-color: $btn-primary-border;
  }
}

// Bootstrap datepicker view change //

ngb-datepicker {
  ngb-datepicker-navigation {
    position: absolute;
    right: 20px;
    top: 15px;
  }

  ngb-datepicker-navigation-select {
    display: none !important;
  }

  .ngb-dp-month-name {
    text-align: left;
    font-size: 24px;
    line-height: 32px;
    color: $text-def;
    padding-left: 28px !important;
    background: transparent !important;
  }

  ngb-datepicker-month-view {
    padding: 0 24px;
  }

  .ngb-dp-month:first-child .ngb-dp-week,
  .ngb-dp-month:last-child .ngb-dp-week {
    padding: 0 !important;
    border-bottom: 0 !important;
    margin: 6px 0;
  }

  .ngb-dp-weekday {
    font-weight: 500;
    font-size: 16px;
    width: 40px;
    line-height: 19px;
    @include flex-container(row, center, center);
  }

  .ngb-dp-day {
    font-size: 16px;
    line-height: 32px;
    width: 40px;
    @include flex-container(row, center, center);
    border-radius: 50%;

    &:focus {
      outline: none;
    }

    .custom-day {
      border-radius: 50%;
      width: 32px;
      height: 32px;
      text-align: center;

      &:focus {
        outline: none;
      }

      &:hover {
        background: $calendar-date-selected-color;
        color: $white;
      }

      &.range {
        &:not(.faded) {
          background: $calendar-date-selected-color;
          color: $white;
        }
      }

      &.faded {
        width: 40px;
        background-color: $date-period-faded;
        border-radius: 0;
      }
    }

    &.ngb-dp-today,
    &.range {
      .custom-day {
        background: $calendar-date-selected-color;
        color: $white;

        &.faded {
          background-color: $date-period-faded;
        }
      }
    }
  }

  .ngb-dp-months {
    padding: 40px 0 20px;
  }
}

.ngb-dp-arrow {
  @include flex-container(row, center, center);
}

/* button loader */
button,
.btn {
  position: relative;
  cursor: pointer;

  &.remove-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -10px;
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: url("/assets/icons/close-circle-3.svg");
    margin-left: 8px;
    padding: 0;
    z-index: 10;

    &:not(:disabled):not(.disabled):active:focus {
      background: url("/assets/icons/close-3.svg") no-repeat center/contain;
      border-color: transparent !important;
    }
  }

  &.loading {
    position: relative;
    cursor: default;
    border-color: transparent;

    &:after {
      content: "";
      position: absolute;
      top: -1px;
      left: -1px;
      width: 100%;
      height: 100%;
      box-sizing: content-box;
      border: 1px solid transparent;
      z-index: 1;
      background: $white-transparent-30;
    }

    .loading {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $loader-colors;
      overflow: hidden;

      .loader-icon {
        display: block;
        width: 20px;
        height: 20px;
        border: 3px solid $loader-icon-border-color;
        border-radius: 50%;
        position: absolute;
        animation: rotate 4s infinite ease;
        margin-top: -10px;
        margin-left: -10px;
        left: 50%;
        top: 50%;

        &:after {
          content: "";
          position: absolute;
          height: 8px;
          width: 100%;
          background: $loader-colors;
          left: 5px;
          top: 50%;
          margin-top: -4px;
        }
      }
    }
  }

  &:focus {
    outline: none;
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* end button loader */
