/* Basic styles */

html,
body {
  -webkit-text-size-adjust: 100%;
  background: $body-bg;
  color: $text-def;
}

body {
  font-family: $font-basic;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: $text-def;
  padding-bottom: 40px;

  &.mobile-body {
    padding-bottom: 0;
    -webkit-overflow-scrolling: touch;
  }
}

main {
  min-height: calc(100vh - 450px);
}

* {
  &:focus {
    outline: none;
  }
}

input,
textarea,
input[ type='password'],
input[ type='email'],
input[ type='text'],
input[ type='number'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
}

input[ type='number'] {
  -moz-appearance: textfield;
}

input[ type='number']:hover,
input[ type='number']:focus {
  -moz-appearance: textfield;
}

.angular-editor-button {

  &[ title='Insert Video'],
  &[ title='Insert Image'] {
    display: none !important;
  }
}

app-root {
  display: block;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5 {
  color: $text-def;
  margin: 0;
}

h1 {
  font-weight: 300;
  font-size: 96px;
  letter-spacing: -1.5px;
}

h2 {
  font-weight: 300;
  font-size: 60px;
}

h3 {
  font-size: 48px;
}

h4 {
  font-size: 34px;
  letter-spacing: .25px;
  font-weight: 700;

  &.offer-title {
    font-size: 34px;
    font-weight: 400;
  }
}

h5 {
  font-size: 24px;
}

h6 {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: .15px;
}

p {
  margin: 0;
}

img {
  max-width: 100%;
  width: auto;
  max-height: 100%;
  height: auto;
  vertical-align: middle;
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    list-style: none;
    padding: 0;
  }
}

select {
  appearance: none !important;
}

input::placeholder {
  color: $placeholder-color !important;
  font-size: 14px;
}

input::-ms-clear {
  display: none;
}

input::-webkit-inner-spin-button {
  display: none;
}

qrcode {
  img {
    margin: 12px auto;
    height: 100%;
    width: 100%;
  }
}

textarea::placeholder {
  color: $placeholder-color !important;
}

a:not([ href]):not([ tabindex]) {
  color: $link-color;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

/* End basic styles */

app-header~.content-wrapper {
  // position: relative;
  z-index: 5;
}

.content-wrapper.main {
  margin-top: 88px;
}

app-operator-offer-item {
  display: block;

  &+app-operator-offer-item {
    margin-top: 8px;
  }
}

app-store-filter .ng-select.ng-select-opened .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  cursor: pointer;
}

.filter-wrapper-inner {
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    white-space: pre-wrap;
  }
}

app-store-filter ng-dropdown-panel.ng-dropdown-panel {
  top: 100% !important;
  bottom: auto !important;

  .ng-dropdown-panel-items {
    min-height: 215px;
  }
}

/* Custom Content Styles */
.custom-content-wrapper {
  .content-page-plain-text {
    font-size: 14px;
    line-height: 28px;
    color: $text-def-darken;
  }

  h4 {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: .15px;
    color: $text-def-darken;
    margin-bottom: 16px;
  }

  h3 {
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: .15px;
    color: $text-def-darken;
    margin-bottom: 16px;
  }

  h2 {
    font-weight: 500;
    font-size: 28px;
    line-height: 28px;
    letter-spacing: .15px;
    color: $text-def-darken;
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 22px;
  }
}

/* End Custom Content Styles */
.page-title {
  font-size: 34px;
  margin-bottom: 48px;
  font-weight: 700;

  &.offseted {
    padding-left: 32px;
  }
}

.offer-input-group {
  .form-group {
    &:last-child {
      margin-left: auto;
    }

    input {
      width: 100px;
    }
  }
}

app-dashbord-admin {
  app-account-settings-edit {
    .form-group-wrapper {
      width: 100% !important;
      max-width: 50% !important;
      flex: auto;
    }

    .page-title {
      display: block !important;
    }
  }
}

.contact-details-items-wrapper {
  &+.contact-details-items-wrapper {
    margin-top: 25px;

    .controls-field .btn.remove-icon {
      display: block;
    }
  }
}

app-account-cheques-period-filter,
app-account-cheques-status-filter {
  display: block;

  .filter-wrapper {
    .form-group .ng-select {
      width: 196px;
    }
  }
}

app-account-cheques-status-filter {
  margin-left: 16px;
}

ngb-datepicker-navigation-select {
  .custom-select {
    text-align: center;
    text-align-last: center;
    appearance: none !important;
    border-radius: 0;
    border-color: $border-color-basic !important;

    &+.custom-select {
      display: none;
    }
  }
}

.input-group {
  label {
    width: 100%;
  }

  .controls-field {
    @include flex-container(row, flex-start, center);
    max-width: 85px;
    width: 100%;
    flex: 1 0 auto;

    .btn {
      @include trans();
      opacity: 1;

      &:hover {
        cursor: pointer;
        opacity: .7;
      }
    }

    .remove-icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: url("/assets/icons/close-circle.svg") no-repeat center/contain;
      margin-left: 8px;
      padding: 0;

      &:not(:disabled):not(.disabled):active:focus {
        background: url("/assets/icons/close-circle-3.svg") no-repeat center/contain;
        border-color: transparent !important;
      }
    }

    .add-icon {
      display: inline-block;
      width: 18px;
      height: 18px;
      margin-left: 14px;
      background: url("/assets/icons/plus.svg") no-repeat center;
      padding: 0;

      &:not(:disabled):not(.disabled):active:focus {
        background: url("/assets/icons/plus.svg") no-repeat center;
        border-color: transparent !important;
      }
    }
  }
}

.remove-icon {
  &.datepicker {
    position: absolute;
    right: 15px;
    z-index: 10;
    top: 50%;
    background: url("/assets/icons/close-icon.svg") no-repeat center rgba($color: $background-light, $alpha: 0.0) !important;
  }
}

.password-stength-wrapper {
  position: absolute;
  left: 0;
  font-size: 10px;
  top: 100%;
  bottom: auto;
  line-height: 16px;
  margin-top: 0;
}

re-captcha>div {
  transform: scale(.925);
  margin-left: -12px;
  margin-top: -8px;
}

.qr_code-popup {
  width: 400px;
  height: 400px;
  margin: 0 auto;

  svg {
    width: 100%;
    height: 100%;
  }
}

.tax-name {
  @include flex-container(row, flex-start, center);
}

app-icon-card {
  display: flex;
}

.table-wrapper {
  margin: 25px 0;
  padding: 16px 0 8px;
  box-shadow: $box-table-shadow;
  border-radius: 6px;
  background: $background-light;

  &.empty-table {
    background: transparent;
    box-shadow: none;
  }

  table.table {
    margin-bottom: 0;
    table-layout: fixed;
  }
}

table {
  th {
    border-bottom: 0 !important;
    border-top: 0 !important;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: .6px;
    text-transform: uppercase;
    background: $background-light;
    vertical-align: middle !important;
    white-space: nowrap;
    color: $th-color;

    &.wide-td {
      width: 220px;
      white-space: pre-wrap;
    }

    &.widest-td {
      width: 350px;
      white-space: pre-wrap;
    }

    &.common-td {
      width: 1px;
    }

    .sort-td-wrapper {
      @include flex-container(row, space-around, center);
    }

    button {
      border: 0;
      background: transparent;
    }

    &.th-product-name-item {
      width: 30%;
    }

    &.th-goods-quantity-item {
      width: 12%;
    }

    &.th-goods-exported-item {
      width: 12%;
    }

    &.th-vat-item {
      width: 6%;
    }

    &.th-number-item {
      width: 6%;
    }
  }

  tr {
    border: 0 !important;
  }

  td {
    border: 0 !important;
    vertical-align: middle !important;

    &.wide-td {
      width: 220px;
    }

    &.widest-td {
      width: 350px;
    }

    &.common-td {
      width: 1px;
    }

    a.product-name {
      max-width: 160px;
      overflow: hidden;
    }

    span {
      color: $text-def;
      font-size: 14px;
      line-height: 22px;
      font-family: $font-basic;

      &.product-name {
        @include text-ellipsis(100%);
      }
    }

    span,
    a {
      display: inline-block;
      @include text-ellipsis();
      vertical-align: middle;
    }

    a {
      text-decoration: underline;
    }
  }
}

.table-striped tbody tr:nth-of-type(odd) {
  background: $table-striped-odd-color !important;
}

.table-striped tbody tr:nth-of-type(even) {
  background: $background-light !important;
}

button.sort {
  &.sorted {
    transform: rotate(180deg);
  }
}

/* operators table */
.operator-table {
  button.sort {
    width: 24px;
    height: 24px;
    line-height: 30px;
    border: none;
    background: url('/assets/img/button-sort.svg') no-repeat center;
    font-size: 0;
    padding: 0;
  }

  .time {
    padding-left: 5px;
  }

  .download-link {
    display: flex;
    align-items: center;

    img {
      margin-right: 12px;
    }
  }
}

.custom-table {
  tbody {
    td {
      color: $gray_400;
    }
  }
}

.row-buttons {
  display: flex;
  justify-content: flex-end;

  .btn.btn-icon {
    padding: 0 15px;
    height: auto;

    &:hover {
      background: none;
    }

    &:active {
      background: none !important;
      border-color: $btn-icon-active-border !important;
    }

    &:focus {
      background: none !important;
      border-color: $btn-icon-active-border !important;
    }

    .icon {
      margin: 0;
      width: 20px;
    }

    svg path {
      fill: $icon-primary;
    }
  }
}

/* operators table end */

.gm-style-iw.gm-style-iw-c {
  padding: 16px !important;
  max-height: 130px !important;

  button {
    top: 8px !important;
    right: 8px !important;
  }
}

.language-select-wrapper {
  margin-right: 30px;

  ng-select {
    .ng-arrow-wrapper {
      background: transparent !important;
    }
  }

  ng-select .ng-select-container {
    border: none !important;
    height: 32px !important;
    background: $violet-transparent-4;
    border-radius: 3px;
    min-width: 69px;

    .ng-value {
      position: static;
      color: $ng-val-color;
      font-weight: 700;
    }

    .ng-placeholder {
      color: $text-def !important;
    }

    &:hover {
      box-shadow: none;
    }
  }
}

app-profile-edit {
  ngb-tabset {
    display: block;
  }
}

.nav {
  border-bottom: 1px solid $border-color-secondary !important;
  border-color: $violet-transparent-50 !important;

  .nav-item {
    line-height: 52px;
    height: 52px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    .nav-link{
      padding: 0;
      span {
        padding: 0 16px;
      }
    }
  }

}

.info-field {
  &.simple {
    font-size: 12px;
  }
}

.submit-controls {
  padding: 32px 0 0;
  margin-top: 66px;
  border-top: 1px solid $border-color-basic;

  .info-field-info {
    padding-right: 32px;
  }

  .btn {
    position: relative;
  }

  .success-message {
    color: $link-color;
  }
}

.tab-content {
  padding-top: 40px;
}

.card-number-wrapper {
  @include flex-container(row, flex-start, center);
}

.disabled-link {
  &:hover {
    cursor: default;
    background: transparent !important;
  }
}

.add-profile {
  max-width: 210px;
  width: 100%;
}

/* user-operator-styles */

.account-opretor-user {
  ngb-tabset {
    display: flex;
  }

  .nav-tabs .nav-item .nav-link:after {
    display: none;
  }

  .nav-tabs .nav-item .nav-link {
    font-weight: 500;
  }

  .nav-tabs .nav-item {
    line-height: 40px;
    height: 40px;
  }

  .nav-tabs {
    flex-direction: column;
    width: 226px;
    margin-right: 30px;
    border-bottom: 0;
  }

  .tab-content {
    width: 100%;
    padding-top: 0;
  }
}

/* end user-operator-styles */

/* tooltip styles */
ngb-tooltip-window {
  .tooltip-inner {
    background-color: $tooltip-bg;
    border-radius: 4px;
    font-size: 14px;
    color: $text-secondary;
    box-shadow: $header-box-shadow;
    padding: 13px 20px;
  }
}

/* tooltip styles end */

/* Skeleton loader */

ngx-skeleton-loader {
  display: block;
  width: 100%;
  height: 100%;
}

.select-loader {
  width: 196px;
  height: 48px;

  &+.select-loader {
    margin-left: 16px;
  }
}

.loader {
  display: block;
  height: 100%;
}

.skeleton-wrapper {
  .item-load {
    padding: 0 8px;
    width: 30%;
  }

  .loader {
    box-shadow: $basic-box-shadow;
  }
}

/* End skeleton loader */

.yandex-captcha-wrapper {
  margin-left: -10px;
}

/* Captcha custom styles */
.captcha-wrapper {
  background: $captcha_bg;
  border: 1px solid $border-color-basic;
  border-radius: 2px;
  padding: 8px;
  margin-bottom: 24px;

  .captcha-field-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 12px !important;

    &.fullwidth {
      input[type='text'] {
        max-width: 100%;
        padding-right: 12px;

        &.form-control.is-invalid {
          padding-right: 12px !important;
        }
      }
    }

    .refresh-captcha {
      width: 20px;
      height: 20px;
      margin-left: 16px;
      flex-shrink: 0;

      span {
        display: inline-block;
        background: url("/assets/icons/refresh.svg") no-repeat center;
        width: 100%;
        height: 100%;
        transform: rotate(0deg);
        @include trans();
      }

      &:hover {
        cursor: pointer;

        span {
          transform: rotate(360deg);
        }
      }
    }

    .invalid-feedback {
      top: 100%;
      margin-top: 15px;
    }
  }

  input[ type='text'] {
    height: 32px;
    max-width: 156px;
    width: 100%;
    padding: 0 12px;
  }

  .invalid-feedback {
    background: transparent;
    bottom: -28px;
  }
}

/* End Captcha custom styles */

/* offers-item */
.offer-item-inner {
  background: $offer-item-bg;
  box-shadow: $basic-box-shadow;
  border-radius: 6px;

  .offer-description {
    line-height: 28px;
    word-wrap: break-word;
    overflow: hidden;
    color: $text-secondary;
  }

  .price-value {
    font-weight: 700;
    font-size: 30px;
    letter-spacing: .25px;
  }

  .currency {
    font-size: 24px;
    padding-left: 5px;
  }

  .cashback-title {
    color: $cashback-title;
    line-height: 22px;
    letter-spacing: .1px;
    font-weight: 300;
  }

  .cashback-value {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: .15px;
    color: $cashback-value;
  }

  .cashback {
    .currency {
      font-size: 16px;
    }
  }

  .marketplace {
    width: 130px;
    font-size: 12px;
    line-height: 16px;
    color: $text-secondary;
  }
}

/* offer-item end */

/* notification page */
.noty-content-wrapper {
  border-radius: 6px;
  background: $term-list-bg;
  box-shadow: $basic-box-shadow;
  padding: 24px 32px;
  margin-bottom: 32px;
}

/* notification page end */

/* offer partner */
.offers-select-wrapper {
  .form-group {
    margin-bottom: 0;
  }
}

/* offer partner end */

/* preloader styles */
.loader-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background: $loader-bg;

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    margin: -20px 0 0 -20px;
    background: $loader-fill;
    border-radius: 50%;
    border: 2px solid $loader-border;
    border-right-color: $loader-border-section;
    animation: 1.5s linear 0s normal none infinite running rotate;
  }
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

/* profile QR code */
.profile_qr-wrapper {
  max-width: 175px;
  width: 100%;
  border: 1px solid $border-color-basic;
  padding: 12px 10px 10px;
  border-radius: 3px;
  @include flex-container(row, space-around, center);
  cursor: pointer;
  @include trans();

  &:hover {
    border-color: $link-color;
    color: $link-color;

    svg {
      path {
        fill: $link-color;
        @include trans();
      }
    }
  }
}

/* End profile QR code  */

.content-wrapper-bg {
  border-radius: 6px;
  box-shadow: $basic-box-shadow;

  .faq-content-item,
  .term-list-item {
    background: $term-list-bg;
    padding: 24px 32px 0;

    &:last-child {
      padding-bottom: 24px;
    }
  }
}

/* Different elements */
.icon {
  display: inline-block;
  width: 32px;
  height: 24px;
  margin-right: 12px;

  svg {
    max-width: 100%;
    max-height: 100%;
  }

  &.ms-card {
    background: url('/assets/img/mastercard.svg') no-repeat center;
  }

  &.visa {
    background: url('/assets/img/visa.svg') no-repeat center;
  }
}

.tips {
  color: $text-secondary;
  position: absolute;
  left: 0;
  bottom: -20px;
  font-size: 12px;
  line-height: 16px;
}

.w-100 {
  max-width: none !important;
}

.select-option {
  font-size: 14px;
  color: $text-def;

  span {
    font-weight: 500;
  }
}

ng-select {
  &+.info-holder {
    right: 19px;
  }
}

ng-select.ng-invalid.ng-touched {
  .ng-select-container {
    border-color: $error-input !important;
  }
}

.info-holder {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}


.file-tooltip .tooltip-inner {
  margin-top: 18px;
  max-width: 300px;
  width: 300px;
}

.file-uploaded-tooltip .tooltip-inner {
  max-width: 400px;
  width: 400px;
}

.list-left {
  color: $text-def;
  display: inline-block;
  margin-right: 15px;
  font-weight: 600;
}

.btn-left-border {
  border-left: 1px solid $border-color-basic;

  &:hover {
    border-left-color: $border-color-basic;
  }
}

.sidebar-link {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: $sidebar-link-color;
  display: block;
  text-decoration: none;
  padding: 10px 0;

  span {
    line-height: 1.2;
  }

  &.active,
  &:hover {
    color: $sidebar-link-color-active;
    text-decoration: none;
  }
}

// TEMPORARY SCROLLBAR STYLE //

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  border-radius: 0;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: $scrollbar-tumb-bg;
}

.subtitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: .1px;
}

.secondary-text {
  color: $text-secondary !important;
}

.go-back-wrapper {
  a {
    padding-left: 25px;
    position: relative;
    color: $text-def;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -12px;
      width: 24px;
      height: 24px;
      display: block;
      background: url("/assets/icons/arrow-left.svg") no-repeat center left,
        transparent;
    }
  }
}

.success-message {
  color: $success-message-color;
}

.offers-count {
  color: $offers-count-color;
  font-size: 30px;
}

.status-value {
  @include text-ellipsis();
}

.status-icon {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 6px;
  flex: 1 0 auto;
  max-width: 6px;

  &.all_statuses {
    background: $all_statuses_icon;
  }

  &.primary {
    background: $icon-primary;
  }

  &.draft {
    width: 15px;
    max-width: 15px;
    height: 15px;
    background: url('~src/assets/icons/status-icon-drafts.svg') no-repeat center;
    background-size: contain;
    border-radius: 0;
  }

  &.validation_pending {
    background: $icon-validation-panding;
  }

  &.refund_pending {
    background: $icon-refound-pending;
  }

  &.paid {
    background: $paid_status;
  }

  &.active {
    background: $active-status-color;
  }

  &.final {
    background: $final_status;
  }

  &.invalid {
    background: $icon-invalid;
  }

  &.invalid_validation {
    background: $invalid_validation_status;
  }

  &.invalid_export_date {
    background: $invalid_status_date;
  }

  &.refunded {
    background: $icon-refounded;
  }

  &.error_send {
    background: $status-error-send;
  }
}

.icon-card {
  display: inline-block;
  width: 32px;
  height: 24px;
  margin-right: 12px;

  &.icon-modal {
    margin: 0 0 24px 0;
    width: 48px;
    height: 36px;
  }

  &>img {
    display: block;
    height: 100%;
  }

  &.product-card-type {
    margin-right: 6px;

    &>img {
      width: 100%;
    }
  }
}

.weak-password {
  color: $weak-pass-color;

  &:after {
    background: $weak-pass-color;
  }
}

.strong-pass {
  color: $strong-pass-color;

  &:after {
    background: $strong-pass-color;
  }
}

.average-pass {
  color: $average-pass;

  &:after {
    background: $average-pass;
  }
}

/* End Different Elements */

/* custom pages styles */

.bottom-contacts {
  margin-top: 70px;

  p {
    font-size: 14px;
    line-height: 28px;
    color: $custop-paragraph-color;
  }

  .conacts-wrapper {
    @include flex-container(row, flex-start, center);
    font-size: 16px;
    line-height: 26px;

    span {
      padding: 0 75px 0 25px;
    }

    .phone {
      background: url('~src/assets/icons/phone-icon.svg') no-repeat left center/contain;
      background-size: 10px;
    }

    .email {
      background: url('~src/assets/icons/letter-icon.svg') no-repeat left center/contain;
      background-size: 16px;
    }
  }
}

.social-contact {
  .ul-shop {
    li {
      max-width: 120px;
    }
  }
}

app-offers {
  ng-select.ng-select .ng-select-container .ng-value-container .ng-placeholder {
    color: $text-def;
  }
}

/* End custom pages styles */

/* Custom Map notify View */

.gm-svpc {
  div+div {
    width: 40px;
    height: 40px;
  }
}

.main-info {
  @include flex-container(row, flex-start, flex-start);
  margin-bottom: 16px;

  .main-info-text {
    padding-left: 10px;

    .name {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -1px;
      color: $dark-color;
      margin-bottom: 8px;

      &.desktop {
        display: block;
      }

      &.mobile {
        display: none;

        &:hover {
          text-decoration: none;
        }
      }

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .type {
      font-size: 12px;
      letter-spacing: -.02em;
      color: $text-secondary;
    }
  }

  .address {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -.02em;
    color: $dark-color;
    overflow-y: auto;
    max-height: 40px;
  }
}

/* End Custom Map notify View */

.form-group {
  .input-group {
    &.date-selector {
      margin-top: 0;
      @include flex-container(row, space-between, center);
      flex-wrap: nowrap;
      width: 100%;
    }

    &:focus {
      outline: none;
    }
  }

  .form-control~mat-datepicker-toggle {
    position: absolute;
    margin-left: 0;
    width: 100%;
    bottom: 0;
    top: 0;
    z-index: 3;
    background: url("/assets/icons/mat.svg") no-repeat center right 16px;

    .mat-icon-button {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 0;
    }

    .mat-datepicker-toggle-default-icon {
      opacity: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.date-selector {
  margin-top: 32px;

  &.date-period {
    margin-top: 0;
    @include flex-container(row, space-between, center);
    flex-wrap: nowrap;
    width: 100%;

    .form-control+mat-datepicker-toggle {
      background: transparent;
    }
  }

  .val {
    line-height: 48px;
  }
}

.title-collapser,
.description-collapser {
  font-size: 14px;
  color: $btn-primary;

  &:hover {
    cursor: pointer;
  }
}

.label-texts {
  display: inline-block;
  line-height: 24px;
  position: relative;
}

.custom-group {
  @include flex-container(row, flex-start, flex-start);
}

/* submit noty style */
.filters-reseted-noty {
  position: fixed;
  top: 100px;
  right: 25px;
  background: $errors-bg;
  box-shadow: $message-box-shadow;
  border-radius: 3px;
  padding: 0 16px;
  height: 48px;
  line-height: 48px;
  z-index: 9999;
  opacity: 0;
  @include trans();

  &.show {
    opacity: 1;
  }

  .noty-wrapper {
    color: $success-message-color;
  }
}

/* Errors Markup */
.errors-wrapper {
  position: fixed;
  right: 10px;
  top: 100px;
  z-index: 1500;

  .error-item {
    background: $errors-bg;
    box-shadow: $message-box-shadow;
    border-radius: 3px;
    padding: 12px 16px;
    height: auto;
    line-height: 24px;

    span {
      &:hover {
        cursor: default;
      }
    }

    &.hard-error {
      color: $error-hard-color;
    }

    &+.error-item {
      margin-top: 16px;
    }
  }

  &.receipt-success {
    top: 32px;
    right: 32px;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    box-shadow: none;
    min-width: 358px;

    .error-item {
      padding: 21px 24px;

      .success-message {
        display: flex;
        align-items: center;
      }
    }

    .icon {
      width: 24px;
      display: inline-flex;
      margin-right: 24px;
    }
  }
}

/* End Errors Markup */

/* submit noty style end */

.body-overflow-mobile {
  .clear-btn-wrapper {
    display: none;
  }
}

.custom-ngbDropdownMenu {
  min-width: 300px;

  .dropdown-item {
    height: 48px;
    font-size: 14px;

    a {
      text-decoration: none !important;
      padding-left: 10px;
    }

    &:hover {
      background: $btn-download-bg;

      a {
        color: $link-color;
      }
    }

    &+.dropdown-item {
      border-top: 1px solid $border-color-basic;
    }
  }
}

.field-required-tip {
  //position: absolute;
  left: 0;
  font-size: 12px;
  top: 100%;
  bottom: auto;
  line-height: 10px;
  margin-top: 4px;
  letter-spacing: -.3px;
  color: $text-secondary;
  padding-right: 32px;
}

.file-uploader-name {
  @include text-ellipsis(380px);
}

.trans {
  transition: all .3s ease-in-out;
}

.text-ellipsis {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-width: 991px) {
  .bottom-contacts .conacts-wrapper {
    flex-direction: column;
    align-items: flex-start;

    .phone {
      margin-bottom: 32px;
    }
  }
}

@media (max-width: 767.5px) {
  .content-wrapper.main {
    margin-top: 64px;
  }

  .errors-wrapper {
    &.receipt-success {
      top: 67px;
      right: 0;
      min-width: 100%;
    }
  }
}
