@import 'src/scss/variables';

.form-group {
  margin-bottom: 19px;
  position: relative;

  .top-label {
    margin-bottom: 20px;
    display: block;
  }

  &:after {
    content: '';
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    position: absolute;
    z-index: 20;
  }

  &.weak-pass {
    position: relative;

    &:after {
      background: $weak-pass-color;
    }
  }

  &.average-pass {
    &:after {
      background: $average-pass;
    }
  }

  &.strong-pass {
    position: relative;

    &:after {
      background: $strong-pass-color;
    }
  }

  &.series {
    max-width: 104px;
  }

  .was-validated .form-control:invalid:focus,
  .form-control.is-invalid:focus,
  .form-control {
    box-shadow: none !important;
    border-color: $error-input;
    padding-right: 8px !important;
  }

  .input-group>.form-control:not(:first-child),
  .input-group>.custom-select:not(:first-child),
  .input-group>.form-control:not(:last-child),
  .input-group>.custom-select:not(:last-child) {
    border-radius: 2px !important;
  }

  .form-control {
    &.searchable {
      padding-right: 32px !important;
    }
  }

  label {
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: .2px;
    text-transform: uppercase;
    color: $label_color;
    white-space: nowrap;
    margin-bottom: 0.4rem;

    &.checkbox-label {
      text-transform: none;
      font-weight: 400;
      &_multiline {
        white-space: normal;
        &::before {
          top: 0;
        }
      }
    }
  }

  .invalid-feedback {
    display: block !important;
    position: absolute;
    left: 0;
    font-size: 10px;
    top: 100%;
    bottom: auto;
    line-height: 10px;
    margin-top: 4px;
    letter-spacing: -.3px;
  }

  &.fns-filter {
    margin-top: 4px;
  }

  input[ type='checkbox'] {
    display: none;

    &+label {
      font-size: 16px;
      line-height: 24px;
      position: relative;
      padding-left: 36px;

      &:hover {
        cursor: pointer;
      }

      &:before {
        content: '';
        position: absolute;
        width: 24px;
        height: 24px;
        left: 0;
        bottom: 0;
        border-radius: 2px;
        background: transparent;
        z-index: 1;
        border: 1px solid $checkbox-color;
      }

      &:after {
        content: '';
        width: 15px;
        height: 12px;
        background: url("/assets/icons/checkbox-white-second.svg");
        position: absolute;
        left: 5px;
        top: 6px;
        z-index: 2;
        opacity: 0;
      }
    }

    &:checked {
      &+label {
        &:after {
          opacity: 1;
        }

        &:before {
          border-color: $checkbox-color;
          background: $checkbox-color;
        }
      }
    }
  }

  .input-group.input-help {
    flex-direction: column;

    .help-txt {
      font-weight: 300;
    }

    .form-control {
      width: 100%;
    }
  }
}

/* Select styles */
angular-editor.is-invalid {
  border: 1px solid $error-input !important;
}

.form-control,
.ng-select .ng-select-container {
  border: 1px solid $border-color-basic !important;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 0 8px 0 16px;
  font-size: 14px;
  line-height: 22px;
  height: 48px !important;
  font-family: $font-basic;
  font-weight: 400;
  color: $text-def;

  &.capitalize {
    text-transform: capitalize;
  }

  &.has-info {
    padding-right: 40px !important;
  }

  &[ readonly] {
    background-color: $violet-transparent-4 !important;
    border-color: transparent !important;

    &:focus,
    &:hover {
      background-color: $background-light;
      border: 1px solid transparent !important;
      cursor: not-allowed;
    }
  }

  &.searchable {
    padding-right: 32px !important;
    background: url('/assets/icons/search-icon.svg') no-repeat right 10px center,
      $white;

    &:focus {
      background: url('/assets/icons/search-icon-green.svg') no-repeat right 10px center,
        $focused-input-bg;
      border-color: $focused-input !important;
    }

    &:hover:not(:focus) {
      border-color: $border-color-basic !important;
    }
  }

  &:hover {
    border-color: $hovered-input-color !important;
  }

  &.is-invalid {
    background: $background-light;
    border-color: $error-input !important;

    &>div {
      position: absolute;
      left: 0;
      bottom: -16px;
    }
  }

  &:focus {
    border-color: $focused-input !important;
    background-color: $focused-input-bg;
    box-shadow: none;
  }
}

ng-select.ng-select {
  width: 100%;

  .ng-value {
    @include flex-container(row, flex-start, center);

    .ng-value-label {
      display: block;
      @include text-ellipsis(100%);
    }
  }

  &.ng-select-opened .ng-select-container {
    cursor: pointer;
    z-index: 998;

    &:focus {
      border-color: $focused-input !important;
      background-color: $focused-input-bg !important;
      box-shadow: none;
    }
  }

  .ng-select-container {
    cursor: pointer;
  }
}

.form-group,
.ng-select.ng-select-multiple {
  input[ type='checkbox'] {
    display: none;

    &+label {
      font-size: 16px;
      line-height: 24px;
      position: relative;
      padding-left: 36px;

      &:hover {
        cursor: pointer;
      }

      &:before {
        content: '';
        position: absolute;
        width: 24px;
        height: 24px;
        left: 0;
        bottom: 0;
        border-radius: 2px;
        background: transparent;
        z-index: 1;
        border: 1px solid $checkbox-color;
      }

      &:after {
        content: '';
        width: 15px;
        height: 12px;
        background: url("/assets/icons/checkbox-white-second.svg");
        position: absolute;
        left: 5px;
        top: 6px;
        z-index: 2;
        opacity: 0;
      }
    }

    &:checked {
      &+label {
        &:after {
          opacity: 1;
        }

        &:before {
          border-color: $checkbox-color;
          background: $checkbox-color;
        }
      }
    }
  }
}

.ng-select-multiple {
  .ng-value {
    &+.ng-input {
      display: none;
    }
  }

  .ng-option {
    position: relative;
    z-index: 1;

    &.disabled {
      color: $placeholder-color;
    }

    label {
      z-index: -1;
    }
  }
}

.multiselect-tag-input.ng-select.ng-select-multiple {
  .ng-select-container {
    height: auto !important;
    min-height: 48px;

    .ng-value-container {
      .ng-input {
        padding-left: 9px;
      }

      .ng-placeholder {
        position: absolute !important;
        top: 13px;
      }
    }
  }

  &.ng-select-focused .ng-select-container {
    border-color: $focused-input !important;
    background-color: $focused-input-bg !important;
    box-shadow: none;
  }

  .ng-arrow-wrapper {
    display: none;
  }

  .ng-value {
    &+.ng-input {
      display: block;
    }
  }
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 16px;
  padding-left: 9px;
  @include text-ellipsis();
  padding-right: 30px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  border-radius: 3px;
  background: $select-val-bg;
  display: flex;
  flex-direction: row;
  padding: 4px;
  margin: 2px;
  font-size: 14px;
  white-space: pre-wrap;

  .ng-value-label {
    @include text-ellipsis(210px);
    line-height: 20px;
  }

  .ng-value-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: url("/assets/icons/close-circle.svg") no-repeat center/contain;
    margin-left: 8px;
    padding: 0;
    font-size: 0;

    &.right {
      border-color: transparent;
    }

    &:hover {
      background-color: transparent;
    }

    &.left {
      border-right: none;
    }
  }
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-top: 0;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.ng-select.ng-select-multiple .ng-select-container {
  padding-left: 0;
}

.ng-select.ng-select-opened>.ng-select-container .ng-arrow {
  top: 3px;
  border: 5px solid transparent;
  border-top: 5px solid $black;
}

ng-dropdown-panel.ng-dropdown-panel {
  margin-bottom: 24px;
  border: none;
  background-color: $white;
  box-shadow: $header-box-shadow;
  border-radius: 2px;
  z-index: 1100;

  .ng-dropdown-header {
    margin: 16px;
    padding: 0;
    border-bottom: 0;
  }

  &.ng-select-top {
    margin-bottom: 4px;
    margin-top: 4px;
  }

  .ng-dropdown-panel-items .ng-option {
    border-radius: 0;
    padding: 12px 16px;

    &.disabled {
      color: $placeholder-color;
    }

    &+.ng-option {
      border-top: 1px solid $border-color-basic;
    }

    label {
      margin-bottom: 0;
    }

    .code {
      display: inline-block;
      width: 35px;
      font-weight: 700;
    }

    &.ng-option-marked {
      background-color: $focused-input-bg;
    }

    &.ng-option-selected {
      cursor: default;
    }
  }

  &.ng-select-bottom {
    margin-top: 6px;
    border-radius: 2px;
  }

  .ng-dropdown-panel-items {
    background: $white;
  }

  .ng-dropdown-footer {
    border-top: 0;
    padding: 12px 7px 5px;
    z-index: 2;
  }
}

.ng-select.ng-select-opened {
  .ng-select-container {
    background-color: $ng-select-container-bg;
    box-shadow: none;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  .ng-dropdown-panel {
    border: none;
    box-shadow: $header-box-shadow;
    border-radius: 2px;
    z-index: 1050;

    .ng-dropdown-header {
      border-bottom: none;
      margin: 16px;
      padding: 0;
    }

    &.ng-select-top {
      margin-bottom: 4px;
      margin-top: 4px;
    }

    .ng-dropdown-panel-items .ng-option {
      border-radius: 0;
      padding: 12px 16px;

      &.disabled {
        color: $placeholder-color;
      }

      &+.ng-option {
        border-top: 1px solid $border-color-basic;
      }

      label {
        margin-bottom: 0;
      }

      .code {
        display: inline-block;
        width: 35px;
        font-weight: 700;
      }

      &.ng-option-marked {
        background-color: $focused-input-bg;
      }

      &.ng-option-selected {
        cursor: default;
      }
    }

    &.ng-select-bottom {
      margin-top: 6px;
      border-radius: 2px;
    }
  }
}

.font-bold {
  font-weight: 500;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  color: $text-def;
  background-color: $ng-selected-bg;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
  font-weight: 400;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: $black transparent transparent;
}

.ng-select.ng-select-single.ng-select-opened .ng-dropdown-panel .ng-dropdown-panel-items {
  .ng-option {
    &+.ng-option {
      border-top: 1px solid $border-color-basic;
    }
  }
}

.ng-select .ng-select-container .ng-value-container {
  padding-left: 0;

  .code {
    display: inline-block;
    width: 35px;
    font-weight: 700;
  }
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
  box-shadow: none !important;
}

ng-select.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: $placeholder-color;
  position: static !important;
  padding-bottom: 0 !important;
  @include text-ellipsis();
}

.ng-select .ng-select-container .ng-value-container .ng-input {
  top: 13px !important;
  padding-left: 8px;
}

/* End select styles */

.form-control.is-invalid:focus,
.ng-select.ng-select-single.is-invalid .ng-select-container {
  border: 1px solid $error-input !important;
  background: $background-light;
}

input {
  caret-color: $text-def;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border-color: $focused-input !important;
  background-color: $focused-input-bg !important;
  box-shadow: none;
}

a:hover {
  color: $link-hover;
  text-decoration: underline;
}

.mat-mdc-icon-button .mat-mdc-button-persistent-ripple::before {
  display: none;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  width: 100%;
}

.ngb-dp-header.bg-light {
  background-color: transparent !important;
}

.input-group textarea.form-control {
  resize: none;
  height: 160px !important;
  padding: 12px 16px;

  &.is-invalid {
    background: $background-light;
    border-color: $error-input !important;
  }
}

.input-group textarea.md-textarea {
  resize: vertical;
  padding: 12px 16px;
  border: 1px solid $border-color-basic !important;
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 14px;
  line-height: 22px;
  min-height: 160px !important;
  font-family: Fira_Sans, Arial, sans-serif;
  font-weight: 400;
  color: $text-def;
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
  overflow-y: scroll;

  &.is-invalid {
    background: $background-light;
    border-color: $error-input !important;
  }

  &:hover {
    border-color: $hovered-input-color !important;
  }

  &:focus {
    border-color: $focused-input !important;
    background-color: $focused-input-bg;
    box-shadow: none;
  }
}

.input-group textarea::-webkit-scrollbar {
  width: 17px;
  cursor: pointer;
}

.input-group textarea::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border: 7px solid $background-light;
}

.input-group textarea {
  cursor: auto;
}

/* Date-time picker */
.cdk-global-scrollblock {
  overflow: initial;
}

.owl-dt-inline-container,
.owl-dt-popup-container {
  width: 300px;

  .owl-dt-calendar {
    height: auto;
  }
}

.owl-dt-calendar-control {
  padding: 20px 30px 32px !important;

  .owl-dt-control-button.owl-dt-control-arrow-button {
    color: $calendar-date-selected-color;
    font-size: 12px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: $btn-transparent-color-active;

    &:first-child {
      position: absolute;
      right: 60px;
    }
  }
}

.owl-dt-calendar-control-content {
  height: 36px;
}

.owl-dt-control-period-button .owl-dt-control-button-content {
  font-size: 24px;
  color: $text-def;
  text-transform: uppercase;
}

.owl-dt-control-period-button {
  position: absolute;
  left: 30px;
}

.owl-dt-control-period-button .owl-dt-control-button-arrow {
  display: none;
}

.owl-dt-container-buttons {
  height: auto;
  padding: 12px 10px;

  .owl-dt-control-button {
    border-radius: 2px !important;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    letter-spacing: .25px;
    text-transform: uppercase;
    font-family: $font-basic;
    height: 48px;
    line-height: 24px;
    padding: 0 16px;
    box-shadow: none !important;
    text-decoration: none !important;
    position: relative;
    @include trans(0);
    background: $btn-primary;
    border-color: $btn-primary-border;
    margin: 0 5px;

    span {
      color: $white;
    }

    &:not(:disabled):not(.disabled):active:focus {
      background: $btn-primary-active;
      border-color: $btn-primary-active-border;
    }

    &:hover {
      background: $btn-primary-hovered;
      border-color: $btn-primary-hovered-border;

      span {
        background-color: transparent;
      }
    }
  }
}

.owl-dt-calendar-table {
  .owl-dt-calendar-header .owl-dt-calendar-table-divider:after {
    display: none;
  }

  .owl-dt-calendar-header th {
    display: none;
  }

  .owl-dt-calendar-header .owl-dt-weekdays th {
    display: table-cell;
    text-align: center;
    padding: 0 0 8px 0;

    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: $calendar-date-selected-color;
    }
  }

  .owl-dt-calendar-cell-selected {
    box-shadow: none;
    background-color: $calendar-date-selected-color;
    color: $white;
    font-size: 13px;

    &.owl-dt-calendar-cell-today,
    &:hover {
      box-shadow: none;
    }
  }

  .owl-dt-calendar-cell-active:focus>.owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected),
  :not(.owl-dt-calendar-cell-disabled):hover>.owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
    background-color: $calendar-date-selected-color;
    color: $white;
  }

  .owl-dt-calendar-cell {
    &[ class *='owl-dt-month-'] {
      .owl-dt-calendar-cell-content {
        width: 110px;
        height: 32px;
        line-height: 30px;
        margin-left: -55px;
        margin-top: -16px;
        font-size: 12px;
        border-radius: 10px;
      }
    }
  }

  .owl-dt-calendar-cell-content {
    font-size: 13px;
    display: block;
    width: 34px;
    height: 34px;
    left: 50%;
    top: 50%;
    margin-top: -17px;
    margin-left: -17px;
    line-height: 32px;
    border-radius: 50%;
  }
}

/* End Date-time picker */

/* Calendar Styles */

.cdk-overlay-container {
  z-index: 1100;

  .mat-calendar-body-today.mat-calendar-body-selected,
  .mat-calendar-body-selected {
    box-shadow: none;
    background-color: $calendar-date-selected-color;
  }

  .mat-calendar-table-header-divider:after {
    display: none !important;
  }

  .mat-calendar-header {
    .mat-calendar-period-button {
      font-size: 24px;

      .mat-calendar-arrow {
        display: none !important;
      }
    }
  }

  .mat-calendar-table-header {
    th {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $calendar-date-selected-color;
    }
  }

  table th:first-child {
    padding-left: 0 !important;
  }

  .mat-calendar-body {
    tr:first-child {
      .mat-calendar-body-label {
        &[ colspan='7'] {
          display: none;
        }

        opacity: 0;
      }
    }
  }
}

.clandar-ico {
  display: none;
}

.form-control {
  &+mat-datepicker-toggle {
    button {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 0 !important;

      svg {
        display: none;
      }

      &.mat-icon-button.cdk-program-focused {
        .mat-button-focus-overlay {
          display: none !important;
        }
      }
    }
  }
}

.ngb-dp-day {
  .bg-primary {
    background-color: $blue-400 !important;

    &:focus {
      box-shadow: none;
    }
  }
}

.mat-datepicker-popup .mat-datepicker-content {
  .mat-calendar {
    width: 375px;
  }

  .mat-calendar-table-header {
    tr {
      &:last-child {
        display: none;
      }
    }
  }

  .mat-calendar-body td {
    padding-top: 17px !important;
    padding-bottom: 18px !important;
  }

  .mat-calendar-previous-button,
  .mat-calendar-next-button {
    color: $calendar-date-selected-color;
    font-size: 12px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: $btn-transparent-color-active;
  }

  .mat-calendar-previous-button {
    margin-right: 5px;
  }

  .mat-calendar-next-button:after,
  .mat-calendar-previous-button:after {
    margin: 7px;
  }

  .mat-calendar-header {
    padding: 20px 30px 16px !important;
  }

  .mat-calendar-content {
    padding: 0 12px;
  }

  .mat-calendar-body-cell-content {
    top: 50%;
    left: 50%;
    margin-top: -17px;
    margin-left: -17px;
    width: 34px;
    height: 34px;
    border-radius: 50%;
  }
}

.mat-calendar-controls {
  @include flex-container(row, space-between, center);
}

ngb-datepicker-month-view {
  .bg-light {
    background-color: transparent !important;
  }
}

.ngb-dp-weekday {
  color: $calendar-date-selected-color !important;
  font-style: normal !important;
  font-family: $font-basic !important;
}

/* End Calendar Styles */

/* Tabs style */
.nav {
  border-color: $violet-transparent-50;

  .nav-item {
    line-height: 52px;
    height: 52px;
    margin-bottom: 0;

    .nav-link {
      border: 0;
      color: $link-color;
      font-size: 16px;
      vertical-align: middle;
      padding: 0;
      background: transparent;
      position: relative;

      span {
        padding: 0 16px;
        display: block;
        height: 100%;
        opacity: .5;

        &:after {
          content: '';
          position: absolute;
          left: 50%;
          right: 50%;
          bottom: -1px;
          height: 2px;
          background: $link-color;
          @include trans(.15, ease);
        }
      }

      &.active,
      &:hover {
        span {
          opacity: 1;
          background: transparent;

          &:after {
            left: 0;
            right: 0;
          }
        }
      }
    }
  }
}

.tab-wrapper {
  padding-bottom: 2px;

  .padding-top-40 {
    padding-top: 40px;
  }
}

.customer-settings {

  .nav-item {
    .nav-link {

      &.active,
      &:hover {
        span {
          opacity: .5;

          &:after {
            background: $link-color;
          }

          &.active,
          &:hover {
            opacity: 1;
            background: transparent;

            &:after {
              left: 0;
              right: 0;
            }
          }
        }
      }

      span {
        opacity: .5;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          left: 50%;
          right: 50%;
          bottom: -1px;
          height: 2px;

          @include trans(.15, ease);
        }

        &.active,
        &:hover {
          opacity: 1;
          background: transparent;

          &:after {
            left: 0;
            right: 0;
          }
        }
      }
    }
  }

}

@media(max-width: 767.5px) {
  .input-group textarea.md-textarea {
    padding: 0;
    background: 0 0;
    border-radius: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-top: 0 !important;
    border-bottom: 2px solid $border-color-basic !important;
    line-height: 30px !important;
    padding-left: 0 !important;
    box-shadow: none !important;
    padding-right: 8px !important;
    overflow-y: scroll;

    &.is-invalid {
      border-bottom: 2px solid $border-color-basic !important;
    }

    &:focus {
      border-bottom: 2px solid $focused-input !important;
    }
  }
}

.ngx-pagination .show-for-sr {
  display: none !important;
}

/* End tabs style */
