/* mixins */
/* Удалить этот миксин, так как он дублирует логику бутстрапа */

@mixin flex-container(
  $flex-direction: row,
  $flex-position: flex-start,
  $align-position: center
) {
  display: flex;
  flex-direction: $flex-direction;
  justify-content: $flex-position;
  align-items: $align-position;
}

@mixin trans($time: .3, $delay: ease-in-out) {
  transition: all $time + s $delay;
}

/* Typography */
$font-sizes: (12, 14, 16, 18, 20, 22, 24, 28, 32, 34, 36, 48, 56, 64, 96, 200);
$margins: (
  0,
  2,
  4,
  5,
  7,
  6,
  8,
  9,
  10,
  12,
  14,
  16,
  20,
  21,
  22,
  24,
  25,
  26,
  28,
  30,
  32,
  34,
  36,
  38,
  40,
  44,
  45,
  48,
  52,
  56,
  64,
  65,
  80,
  100,
  142,
  170,
  310
);
$sides: (top, bottom, left, right);

@mixin text-ellipsis($width: 100%) {
  max-width: $width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@each $size in $font-sizes {
  .fz-#{$size} {
    font-size: $size + px !important;
  }
}

@each $size in $font-sizes {
  @media ( max-width: 767.5px ) {
    .fz-sm-#{$size} {
      font-size: $size + px !important;
    }
  }
}

@each $space in $margins {
  .mx-#{$space} {
    margin-left: #{$space}px !important;
    margin-right: #{$space}px !important;
  }

  .my-#{$space} {
    margin-top: #{$space}px !important;
    margin-bottom: #{$space}px !important;
  }

  .m-#{$space} {
    margin: #{$space}px !important;
  }

  .px-#{$space} {
    padding-left: #{$space}px !important;
    padding-right: #{$space}px !important;
  }

  .py-#{$space} {
    padding-top: #{$space}px !important;
    padding-bottom: #{$space}px !important;
  }

  .p-#{$space} {
    padding: #{$space}px !important;
  }

  .p-top-#{$space} {
    padding-top: #{$space}px !important;
  }

  .p-bottom-#{$space} {
    padding-bottom: #{$space}px !important;
  }

  .p-left-#{$space} {
    padding-left: #{$space}px !important;
  }

  .p-right-#{$space} {
    padding-right: #{$space}px !important;
  }

  .m-top-#{$space} {
    margin-top: #{$space}px !important;
  }

  .m-bottom-#{$space} {
    margin-bottom: #{$space}px !important;
  }

  .m-left-#{$space} {
    margin-left: #{$space}px !important;
  }

  .m-right-#{$space} {
    margin-right: #{$space}px !important;
  }
}
